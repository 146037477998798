@font-face {
  font-family: 'BrandonText';
  src: url('../fonts/BrandonText-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Cuprum';
  src: url('../fonts/Montserrat-Regular.ttf');
}
